import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component'
import { makeApiRequest } from "../axiosService/ApiCall";
import { toast } from "react-toastify";

function Withdrawrequest() {

    const [userData, setUserData] = useState([])

    const userRequest = async () => {
        try {
            let params = {
                url: "user-withdrawRequest",
                method: "GET"
            }
            const resp = await makeApiRequest(params)
            if (resp.status) {
                setUserData(resp.data)
            } else {
                setUserData([])
            }
        } catch (error) {
            console.log("error---");
        }
    }

    useEffect(() => {
        userRequest()
    }, [])

    const columnsone = [
        {
            name: 'S.No',
            selector: (row, index) => index + 1,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.userData.email,
            sortable: true,
        },
        {
            name: 'To Address',
            selector: row => row.toaddress,
            sortable: true,
        },
        {
            name: 'Symbol',
            selector: row => row.moveCur,
            sortable: true,
        },
        // {
        //     name: 'TX-Hash',
        //     selector: row => row.txnId || "-",
        //     sortable: true,
        // },
        {
            name: 'Amount',
            selector: row => row.amount,
            sortable: true,
        },
        {
            name: 'Action',
            cell: (row) => (
                <div className='d-flex align-items-center gap-3'>
                    {/* {loadingId.id === row.withdrawHistory._id ? (
                  <span className="loader">Processing...</span>
                ) : ( */}
                    <>
                        <button
                            className='Aproved custom-main-button'
                            onClick={() => handleAction(row, 'approve')}
                        >
                            Approve
                        </button>
                        <button
                            className='reject custom-main-button'
                            onClick={() => handleAction(row._id, 'reject')}
                        >
                            Reject
                        </button>
                    </>
                    {/* )} */}
                </div>
            ),
            width: "300px"
        },

    ];

    async function handleAction(data, type) {
        try {
            let userDatas = {
                id: data._id,
                userId: data.userId,
                toaddress: data.toaddress,
                amount: data.amount,
                symbol: data.moveCur,
                type: type
            }
            let params = {
                url: "admin-withdrawalProcess",
                method: "POST",
                data: userDatas
            }

            const response = await makeApiRequest(params)
            if (response.status) {
                toast.success(response.message)
            } else {
                toast.warn(response.message)
            }
        } catch (error) {
            console.log('error----', error)
        }
    }

    return (
        <div className='App text-dark'>
            <div className='container-fluid'>
                <div className='custom-analytic'>
                    <h3>User Withdraw Request</h3>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='liquidity-table-1'>
                                <DataTable
                                    columns={columnsone}
                                    data={userData}
                                    theme="solarized"
                                    defaultSortAsc={true}
                                    pagination
                                    highlightOnHover
                                    dense
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Withdrawrequest